import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home">
      <h3>Welcome to Experts Panel!</h3>
      <div>
        <>
          <Link to="register">
            <h4>Register</h4>
          </Link>
          <Link to="login">
            <h4>Login</h4>
          </Link>
        </>
      </div>
    </div>
  );
};

export default Home;
