import * as React from "react";
import { useState, useEffect } from "react";
//import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import './profile.css'
import DashboardLayout from "../components/dashboard-layout";
import Container from '@mui/material/Container';
import { useAuthContext } from "../hooks/useAuthContext";

// import ImageUploading from 'react-images-uploading';

export default function Profile() {
  const { doctor, dispatch } = useAuthContext();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");

  const [currentpassword, setCurrentpassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [confirmnewpassword, setConfirmnewpassword] = useState("");

  const [pwdAvail, setPwdAvail] = useState(true)

  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);

  const [error1, setError1] = useState(null);
  const [emptyFields1, setEmptyFields1] = useState([]);

  const [doc, setDoc] = useState("");

  //const navigate = useNavigate();

  useEffect(() => {
    const fetchDoctor = async () => {
      const check = localStorage.getItem("doctor");
      if (check) {
        const response = await fetch(
          "/server/doctors/getDoctor/" + doctor.doctor._id,
          {
            headers: {
              Authorization: `Bearer ${doctor.token}`,
            },
          }
        );
        const json = await response.json();

        if (response.ok) {
          setDoc(json);
          //console.log(json)
          setFirstname(json.firstname);
          setLastname(json.lastname);
          setEmail(json.email);
          // { json.password === "" ? setPwdAvail(true) : setPwdAvail(false) }
          { json.hasOwnProperty("password") || json.password === "" ? setPwdAvail(false) : setPwdAvail(true) }
        }
        else {
          dispatch({ type: "LOGOUT", isAuthenticated: false });
        }
      } else {
        //navigate('/login');
        dispatch({ type: "LOGOUT", isAuthenticated: false });
      }
    };

    fetchDoctor();
  }, []);

  const handleSubmit = async (e) => {
    const check = localStorage.getItem("doctor");
    if (check) {
      e.preventDefault();

      const docDet = { firstname, lastname, email };

      console.log(docDet);

      const response = await fetch("/server/doctors/updateDoctor/" + doc._id, {
        method: "PATCH",
        body: JSON.stringify(docDet),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${doctor.token}`,
        },
      });
      const json = await response.json();

      if (!response.ok) {
        //setError(json.error)
        setError(<Alert severity="error">{json.error}</Alert>);
        setEmptyFields(json.emptyFields);
      }
      if (response.ok) {
        setError(null);
        setEmptyFields([]);
        alert("Doctor Details Updated");
      }
    } else {
      dispatch({ type: "LOGOUT", isAuthenticated: false });
    }
  };

  const handlePwdUpdt = async (e) => {
    const check = localStorage.getItem("doctor");
    if (check) {
      e.preventDefault();
      const docPwd = { currentpassword, newpassword, confirmnewpassword };

      console.log(docPwd);

      const response1 = await fetch("/server/doctors/changePwd/" + doc._id, {
        method: "PATCH",
        body: JSON.stringify(docPwd),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${doctor.token}`,
        },
      });
      const json = await response1.json();

      if (!response1.ok) {
        setError1(json.error);
        setEmptyFields1(json.emptyFields);
      }
      if (response1.ok) {
        setError1(null);
        setEmptyFields1([]);
        setCurrentpassword("");
        setNewpassword("");
        setConfirmnewpassword("");
        alert("Doctor Password Changed");
      }
    } else {
      dispatch({ type: "LOGOUT", isAuthenticated: false });
    }
  };

  const handlePrimePwdUpdt = async (e) => {
    const check = localStorage.getItem("doctor")
    if (check) {
      e.preventDefault();
      const docPwd = { newpassword, confirmnewpassword }

      console.log(docPwd)

      const response1 = await fetch("/server/doctors/savePrimePassword/" + doc._id, {
        method: "PATCH",
        body: JSON.stringify(docPwd),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${doctor.token}`,
        },
      });
      const json = await response1.json();

      if (!response1.ok) {
        setError1(json.error);
        setEmptyFields1(json.emptyFields);
      }
      if (response1.ok) {
        setError1(null);
        setEmptyFields1([]);
        setNewpassword("");
        setConfirmnewpassword("");
        setPwdAvail(false)
        alert("Doctor Password Saved");
      }
    } else {
      dispatch({ type: "LOGOUT", isAuthenticated: false });
    }
  }

  // Image Upload
  // const [images, setImages] = useState('');
  // const maxNumber = 69;

  // const onChange = (imageList, addUpdateIndex) => {
  //     // data for submit
  //     console.log(imageList, addUpdateIndex);
  //     setImages(imageList);
  // };

  // const btncirimgupld = {
  //     borderRadius: '50px',
  //     width: '100px',
  //     height: '100px',
  //     margin: '15px auto 0 auto',
  //     position: 'absolute',
  //     top: '0',
  //     left: '0',
  //     right: '0',
  //     cursor: 'pointer',
  //     border: '0',
  //     display: 'flex',
  //     alignItems: 'center',
  //     background: 'rgb(243, 242, 242)',
  //     padding: '15px'
  // }
  // const btnup = {
  //     borderRadius: '50px',
  //     width: '130px',
  //     height: '130px',
  //     cursor: 'pointer',
  //     opacity: 0,
  //     display: 'inline-block'

  // }
  // const imgwrap = {
  //     position: 'absolute',
  //     top: '0',
  //     left: '0',
  //     right: '0',
  // }

  // const bgcontainer = {
  //     textAlign: 'center',
  //     width: '130px',
  //     height: '130px',
  //     margin: '0 auto',
  //     position: 'relative',
  //     border: '1px dashed rgba(145, 158, 171, 0.32)',
  //     borderRadius: '50%'
  // }
  // const imguplodbg = {
  //     width: '100px', height: '100px', background: '#f3f2f2', position: 'absolute', borderRadius: '50%', left: '0', right: '0', margin: '0 auto', top: '15px'
  // }
  // const m0 = {
  //     margin: 0,
  //     padding: '10px',
  //     borderRadius: '50px',
  //     width: '100px',
  //     height: '100px',
  //     position: 'absolute',
  //     top: '0',
  //     left: '0',
  //     right: '0',
  //     cursor: 'pointer',
  //     border: '0',
  //     display: 'flex',
  //     alignItems: 'center',
  //     background: 'rgb(243, 242, 242)',
  // }

  return (
    <>
      <DashboardLayout>
        {
          <React.Fragment >
            <Container className="profilej contaier">
              <Typography variant="h6" gutterBottom>
                My Profile
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="firstname"
                      name="firstname"
                      label="First name"
                      fullWidth
                      autoComplete="given-name"
                      variant="standard"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      className={
                        (emptyFields || "").includes("firstname") ? "error" : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="lastname"
                      name="lastname"
                      label="Last name"
                      fullWidth
                      autoComplete="family-name"
                      variant="standard"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      className={
                        (emptyFields || "").includes("lastname") ? "error" : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="email"
                      name="email"
                      label="Email ID"
                      fullWidth
                      autoComplete="Email ID"
                      variant="standard"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={
                        (emptyFields || "").includes("email") ? "error" : ""
                      }
                    />
                  </Grid>
                  {/* <Grid item md={12} xs={12}>

                                    <ImageUploading value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageUpdate,
                                            // onImageRemove,
                                            dragProps,
                                        }) => (
                                            // write your building UI
                                            <div className="upload__image-wrapper" style={bgcontainer}>

                                                <p style={btncirimgupld}
                                                    variant="caption" display="block"
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                >
                                                    Upload photo
                                                </p>

                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item" style={imguplodbg}>
                                                        <img style={m0} src={image['data_url']} alt="" width="100" />
                                                        <div className="image-item__btn-wrapper" style={imgwrap}>
                                                            <p style={btnup} onClick={() => onImageUpdate(index)}>Update</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>

                                    <Typography variant="caption" display="block" sx={{ p: 2, width: '100%', textAlign: 'center' }}>Allowed .jpeg, .jpg, .png, .gif max size of 3.1 MB</Typography>

                                </Grid> */}

                  <Grid item md={12} xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                  {error && <div className="error">{error}</div>}
                </Grid>
              </Box>
              <hr style={{ margin: '30px 0' }} />

              <Typography variant="h6" gutterBottom>
                {!pwdAvail ? 'Change Password' : 'Save Password'}
              </Typography>
              {!pwdAvail ?

                <Box
                  component="form"
                  noValidate
                  onSubmit={handlePwdUpdt}
                  sx={{ mt: 3 }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="currentpassword"
                        name="currentpassword"
                        label="Enter Current Password"
                        fullWidth
                        variant="standard"
                        type="password"
                        value={currentpassword}
                        onChange={(e) => setCurrentpassword(e.target.value)}
                        className={
                          (emptyFields1 || "").includes("currentpassword")
                            ? "error"
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="newpassword"
                        name="newpassword"
                        label="Enter New Password"
                        fullWidth
                        variant="standard"
                        type="password"
                        value={newpassword}
                        onChange={(e) => setNewpassword(e.target.value)}
                        className={
                          (emptyFields1 || "").includes("newpassword")
                            ? "error"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="confirmnewpassword"
                        name="confirmnewpassword"
                        label="Confirm New Password"
                        fullWidth
                        variant="standard"
                        type="password"
                        value={confirmnewpassword}
                        onChange={(e) => setConfirmnewpassword(e.target.value)}
                        className={
                          (emptyFields1 || "").includes("confirmnewpassword")
                            ? "error"
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>

                  <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Update Password
                  </Button>
                  {error1 && <div className="error">{error1}</div>}
                </Box>

                :

                <Box
                  component="form"
                  noValidate
                  onSubmit={handlePrimePwdUpdt}
                  sx={{ mt: 3 }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="newpassword"
                        name="newpassword"
                        label="Enter New Password"
                        fullWidth
                        variant="standard"
                        type="password"
                        value={newpassword}
                        onChange={(e) => setNewpassword(e.target.value)}
                        className={
                          (emptyFields1 || "").includes("newpassword")
                            ? "error"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="confirmnewpassword"
                        name="confirmnewpassword"
                        label="Confirm New Password"
                        fullWidth
                        variant="standard"
                        type="password"
                        value={confirmnewpassword}
                        onChange={(e) => setConfirmnewpassword(e.target.value)}
                        className={
                          (emptyFields1 || "").includes("confirmnewpassword")
                            ? "error"
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>

                  <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Save Password
                  </Button>
                  {error1 && <div className="error">{error1}</div>}
                </Box>

              }
            </Container>
          </React.Fragment>
        }
      </DashboardLayout>
    </>
  );
}
