import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from "jwt-decode";

import { useState } from "react"
import { useLogin } from "../hooks/useLogin"
import { useGauth } from "../hooks/useGauth"

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="/">
                AYANA Experts Panel
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function SignIn() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { login, error, isLoading, emptyFields } = useLogin()
    const { gAuthlogin } = useGauth()

    const handleSubmit = (event) => {
        event.preventDefault();
        // const data = new FormData(event.currentTarget);
        // console.log({
        //     email: data.get('email'),
        //     password: data.get('password'),
        // });
        login(email, password)
    };

    const handleLogin = (credentialResponse) => {
        //console.log(credentialResponse)
        var userObject = jwtDecode(credentialResponse.credential);
        //let tmpgData = JSON.parse(userObject);

        //console.log(userObject)
        gAuthlogin(userObject.given_name, userObject.family_name, userObject.email, userObject.sub)
    }
    
    const gCliId = process.env.REACT_APP_GOOGLE_CLIENT_ID

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{ p: 2 }}>
                        Ayana Experts Panel
                    </Typography>
                    <img
                        style={{ width: 100, height: 100, margin: '0 auto' }}
                        src={`assets/images/ayana-logo.png`}
                        //srcSet={`assets/images/ayana-logo.png`}
                        alt="Ayana"
                        loading="lazy"
                    />
                    <Typography component="h1" variant="h5">
                        Sign In
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            onChange={(e) => setEmail(e.target.value)}
                            className={(emptyFields || '').includes('email') ? 'error' : ''}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                            className={(emptyFields || '').includes('password') ? 'error' : ''}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isLoading}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            {/* <Grid item>
                                <Link href="/register" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Box>
                {error && <div className="error">{error}</div>}

                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <GoogleOAuthProvider
                        clientId={gCliId}
                    >
                        <GoogleLogin
                            width="260px"
                            size="large "
                            onSuccess={(credentialResponse) => {
                                // console.log(credentialResponse);
                                handleLogin(credentialResponse);
                            }}
                            onError={() => {
                                console.log("Login Failed");
                                //handleFailure();
                            }}
                        />
                    </GoogleOAuthProvider>
                </Box>

                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}