import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

import './App.css'
import Home from './pages/Home'
import Register from './pages/Register'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Patients from './pages/Patients'
import Questionnaires from './pages/Questionnaires'
import Results from './pages/Results'
import ResultsOfAI from './pages/ResultsOfAI'
import Profile from './pages/Profile'
import Report from './pages/Report'
import ReportOfAI from './pages/ReportOfAI'

function App() {

  const { doctor } = useAuthContext()

  return (
    <div className="App">
      <BrowserRouter>
        <div className="pages">
          <Routes>
            <Route
              path="/"
              element={!doctor ? <Login /> : <Navigate to="/dashboard" />}
            />
            <Route
              path="register"
              element={!doctor ? <Register /> : <Navigate to="/" />}
            />
            <Route
              path="login"
              element={!doctor ? <Login /> : <Navigate to="/" />}
            />
            <Route
              path="dashboard"
              element={doctor ? <Dashboard /> : <Navigate to="/" />}
            />
            <Route
              path="profile"
              element={doctor ? <Profile /> : <Navigate to="/" />}
            />
            <Route
              path="ListOfPatients"
              element={doctor ? <Patients /> : <Navigate to="/" />}
            />
            <Route
              path="/view-questionnaires/:id"
              element={doctor ? <Questionnaires /> : <Navigate to="/" />}
            />
            <Route
              path="/get-results/:id"
              element={doctor ? <Results /> : <Navigate to="/" />}
            />
            <Route
              path="/get-results-of-ai/:id"
              element={doctor ? <ResultsOfAI /> : <Navigate to="/" />}
            />
            <Route
              path="/get-patient-report/:id"
              element={doctor ? <Report /> : <Navigate to="/" />}
            />
            <Route
              path="/get-patient-report-of-ai/:id"
              element={doctor ? <ReportOfAI /> : <Navigate to="/" />}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
