import React, { createContext, useReducer, useEffect } from "react"

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { doctor: action.payload, status: 'loggedIn' }
        case 'LOGOUT':
            return { doctor: null, status: 'loggedOut' }
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(authReducer, {
        doctor: JSON.parse(localStorage.getItem('doctor'))
    })

    useEffect(() => {
        const doctor = JSON.parse(localStorage.getItem('doctor'))

        //console.log(doctor)

        if (doctor) {
            dispatch({ type: 'LOGIN', payload: doctor })
        }

        if (!doctor) {
            dispatch({ type: 'LOGOUT' })
        }
    }, [])

    //console.log("AuthContext state: ", state)

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AuthContext.Provider>
    )

}