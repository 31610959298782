import * as React from "react";
import { useState, useEffect } from "react";

import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../pages/Components/Dashboard/Dashboardcontents/Title";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardLayout from "../components/dashboard-layout";
import { useAuthContext } from "../hooks/useAuthContext";

const Patients = () => {
  const { doctor, dispatch } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [docname, setDocname] = useState("");
  const [usid, setUsid] = useState("");
  const [curDoc, setCurDoc] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const check = localStorage.getItem("doctor");

  const [users, setUsers] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const check = localStorage.getItem("doctor");
      if (check) {
        setLoading(true);
        const response = await fetch(
          "/server/patients/" + doctor.doctor._id,
          {
            headers: {
              Authorization: `Bearer ${doctor.token}`,
            },
          }
        );
        const json = await response.json();

        if (response.ok) {
          setLoading(false);
          setUsers(json);
        } else {
          setLoading(false);
          dispatch({ type: "LOGOUT", isAuthenticated: false });
        }
        // console.log(response);
      } else {
        setLoading(false);
        dispatch({ type: "LOGOUT", isAuthenticated: false });
      }
    };

    fetchUsers();
  }, []);

  return (
    <>
      <DashboardLayout>
        {
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <React.Fragment>
                <Title>Patients List</Title>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>

                        <TableCell sx={{ fontWeight: "bold" }}>
                          Gender
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Age Group{" "}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Location{" "}
                        </TableCell>

                        <TableCell sx={{ fontWeight: "bold" }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users &&
                        users.map((usr) => (
                          <TableRow key={usr._id}>
                            <TableCell>****</TableCell>
                            <TableCell>{usr.gender}</TableCell>
                            <TableCell>{usr.age_group}</TableCell>
                            <TableCell>{usr.geo_location}</TableCell>

                            <TableCell>
                              <Button
                                component={Link}
                                href={"view-questionnaires/" + usr._id}
                                variant="contained"
                                color="primary"
                              >
                                View Journeys
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            </Container>
          </Box>
        }
        {loading ? (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1000,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
      </DashboardLayout>
    </>
  );
};

export default Patients;
