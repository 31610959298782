import { useAuthContext } from "./useAuthContext"

export const useLogout = () => {
    const { dispatch } = useAuthContext()

    const logout = () => {
        // remove doctor token from local storage
        localStorage.removeItem('doctor')
        localStorage.clear()
        //localStorage.setItem('logout-event', 'logout' + Math.random());
        // dispatch the logout function
        dispatch({ type: 'LOGOUT', isAuthenticated: false })
    }

    return { logout }
}