import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    usecl: {


        '& th:nth-of-type(1)': {

            backgroundColor: '#fff !important',
        },


        '& th:last-child': {

            backgroundColor: '#FFFF00 !important',
        },



        '& tr > td:last-child': {

            backgroundColor: '#FFFF00 !important',
        },

    },


    usecl2: {


        '& th:nth-of-type(1)': {

            backgroundColor: '#FCD5B4 !important',
        },

        '& th:last-child': {

            backgroundColor: '#FFFF00 !important',
        },
        '& td:last-child': {
            backgroundColor: '#FFFF00 !important',
        },

        '& td:nth-of-type(1)': {

            backgroundColor: '#FCD5B4 !important',
        },
        '& tr:nth-of-type(6) td:nth-of-type(2)': {

            backgroundColor: '#FFFF00 !important',
        },
        '& tr:nth-of-type(5) td:nth-of-type(2)': {

            backgroundColor: '#FFFF00 !important',
        },

        '& tr:nth-of-type(6) td:last-child': {

            backgroundColor: '#fff !important',
        },
        '& tr:nth-of-type(5) td:last-child': {

            backgroundColor: '#fff !important',
        }


    },

    usecl3: {


        '& tr:nth-of-type(1) td:nth-of-type(3), & tr:nth-of-type(2) td:nth-of-type(3), & tr:nth-of-type(3) td:nth-of-type(3), & tr:nth-of-type(4) td:nth-of-type(3)': {

            backgroundColor: '#00FF00 !important',
        },

        '& tr:nth-of-type(1) td:nth-of-type(5), & tr:nth-of-type(3) td:nth-of-type(5)': {

            backgroundColor: '#FFFF00 !important',
        },
        '& tr:nth-of-type(2) td:nth-of-type(5), & tr:nth-of-type(4) td:nth-of-type(5)': {

            backgroundColor: '#4B0082 !important',
        },


        '& tr:nth-of-type(6) td:nth-of-type(3), & tr:nth-of-type(7) td:nth-of-type(3)': {

            backgroundColor: '#46BDC6 !important',
        },

    }




}));