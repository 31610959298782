import * as React from "react";
import { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";

import { yellow } from "@mui/material/colors";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import DashboardLayout from "../components/dashboard-layout";

import { useAuthContext } from "../hooks/useAuthContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const yellowColor = yellow[500];

const Questionnaires = () => {
  const navigate = useNavigate()
  let { id } = useParams();

  const { doctor, dispatch } = useAuthContext();

  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const check = localStorage.getItem("doctor");
      if (check) {
        setLoading(true);
        const response = await fetch(
          "/server/getAllUsers/view-questionnaires/" + id + '/' + doctor.doctor._id,
          {
            headers: {
              Authorization: `Bearer ${doctor.token}`,
            },
          }
        );
        const json = await response.json();

        if (json[0]) {
          setUsers(json);
        }
        else {
          navigate('/')
        }
          
        setLoading(false);
      } else {
        setLoading(false);
        dispatch({ type: "LOGOUT", isAuthenticated: false });
      }
    };

    fetchUsers();
  }, []);

  let quarterText = ["Jan - Mar", "Apr - Jun", "Jul - Sep", "Oct - Dec"];

  return (
    <>
      <DashboardLayout>
        {
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Card variant="outlined">
              <CardContent>
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography color="text.primary">
                    <Link to="/ListOfPatients">Patients List</Link>
                  </Typography>
                  <Typography color="text.primary">Journeys</Typography>
                  {/*<Typography>
                    {users == null || users == ""
                      ? "No Records"
                      : users[0].patient_meta.email}
                  </Typography>*/}
                </Breadcrumbs>
              </CardContent>
            </Card>

            <Container maxWidth="sm" sx={{ p: 2 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={2}
                  columns={16}
                  style={{ display: "block" }}
                >
                  <Grid item>
                    {users == null || users == "" ? "No Records Found!" : ""}
                    {users &&
                      users.map((usr, index) => (
                        <Card
                          variant="outlined"
                          key={index}
                          style={{ margin: "0 0 15px 0", padding: "15px" }}
                        >
                          <CardContent>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Chip
                                size="small"
                                variant="filled"
                                sx={
                                  usr.view_status == true
                                    ? { bgcolor: "#81c784" }
                                    : { bgcolor: "#ffeb3b" }
                                }
                                label={
                                  usr.view_status == true ? "Viewed" : "Pending"
                                }
                              />
                              <Chip
                                size="small"
                                variant="filled"
                                sx={
                                  usr.patient_notes == null
                                    ? { bgcolor: "#ffeb3b" }
                                    : { bgcolor: "#81c784" }
                                }
                                label={
                                  usr.patient_notes == null
                                    ? "Not Analysed"
                                    : "Analysed"
                                }
                              />
                            </Box>

                            <Typography
                              sx={{ fontSize: 22 }}
                              color="text.grey"
                              gutterBottom
                            >
                              {usr.aidqnid == "6363a67082cd85ddf4e25174" ||
                                usr.aidqnid == "63f9b4d20a4d31daa474c106"
                                ? "My Body Signals "
                                : "Auto Immune "}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 16 }}
                              color="text.grey"
                              gutterBottom
                            >
                              {quarterText[
                                Math.floor(
                                  (new Date(
                                    parseInt(usr.createdAt)
                                  ).getMonth() +
                                    3) /
                                  3
                                ) - 1
                              ] +
                                " " +
                                new Date(parseInt(usr.createdAt)).getFullYear()}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              sx={{ width: "100%" }}
                              size="medium"
                              component={Link}
                              to={usr.q_type === 'autoimmune-propensity' ? '/get-results-of-ai/' + usr._id : '/get-results/' + usr._id}
                              variant="contained"
                              color="primary"
                            >
                              View Results
                            </Button>
                            <Button
                              sx={{ width: "100%" }}
                              size="medium"
                              component={Link}
                              to={usr.q_type === 'autoimmune-propensity' ? '/get-patient-report-of-ai/' + usr._id : '/get-patient-report/' + usr._id}
                              variant="contained"
                              color="primary"
                            >
                              View Report
                            </Button>
                          </CardActions>
                        </Card>
                      ))}
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        }
        {loading ? (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1000,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
      </DashboardLayout>
    </>
  );
};

// Users.getLayout = (user) => (
//     <DashboardLayout>
//         {user}
//     </DashboardLayout>
// );

export default Questionnaires;
