import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { useAuthContext } from "../hooks/useAuthContext";

import DashboardLayout from "../components/dashboard-layout";
import { styled } from '@mui/material/styles';

const CustomizedCard = styled(Card)`
background-color: #fff;
color: #212B36;
/* -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
/* transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
border-radius: 12px;
border: 1px solid rgba(145, 158, 171, 0.24);
overflow: hidden;
box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12);

`;

export default function Dashboard() {
  const { doctor, dispatch } = useAuthContext();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [allCnts, setAllCnts] = useState("");

  useEffect(() => {
    const fetchDoctor = async () => {
      const check = localStorage.getItem("doctor");
      if (check) {
        const response = await fetch(
          "/server/doctors/getDoctor/" + doctor.doctor._id,
          {
            headers: {
              Authorization: `Bearer ${doctor.token}`,
            },
          }
        );
        const json = await response.json();

        if (response.ok) {
          setFirstname(json.firstname);
          setLastname(json.lastname);
          setEmail(json.email);
        } else {
          dispatch({ type: "LOGOUT", isAuthenticated: false });
        }

        const response_cnt = await fetch(
          "/server/getAllCounts/" + doctor.doctor._id,
          {
            headers: {
              Authorization: `Bearer ${doctor.token}`,
            },
          }
        );
        const json_cnt = await response_cnt.json();
        if (response_cnt.ok) {
          setAllCnts(json_cnt);
        }
        else {
          dispatch({ type: "LOGOUT", isAuthenticated: false });
        }
      } else {
        dispatch({ type: "LOGOUT", isAuthenticated: false });
      }
    };

    fetchDoctor();
  }, []);

  return (
    <DashboardLayout>
      {
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Box>

              <CustomizedCard sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Welcome {firstname + " " + lastname} !!
                  </Typography>
                  <Typography variant="h5" component="div"></Typography>
                  <Typography sx={{ mb: 0 }} color="text.secondary">
                    {email}
                  </Typography>
                </CardContent>
              </CustomizedCard>

            </Box>
            <Box sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} style={{ textDecoration: "none" }}>
                  <CustomizedCard variant="outlined">
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center", p: 2, py: 0 }}
                        component="h5"
                        variant="h5"
                      >
                        Total Patients
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", pt: 1 }}
                        variant="h5"
                        color="text.primary"
                      >
                        {allCnts.ptCT}
                      </Typography>
                    </CardContent>
                  </CustomizedCard>
                </Grid>
                <Grid item xs={12} md={4} style={{ textDecoration: "none" }}>
                  <CustomizedCard variant="outlined">
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center", p: 2, py: 0 }}
                        component="h5"
                        variant="h5"
                      >
                        Total Journeys
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", pt: 1 }}
                        variant="h5"
                        color="text.primary"
                      >
                        {allCnts.qsCT}
                      </Typography>
                    </CardContent>
                  </CustomizedCard>
                </Grid>

                <Grid item xs={12} md={4} style={{ textDecoration: "none" }}>
                  <CustomizedCard variant="outlined">
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center", p: 2, py: 0 }}
                        component="h5"
                        variant="h5"
                      >
                        Unopened Journeys
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", pt: 1 }}
                        variant="h5"
                        color="text.primary"
                      >
                        {allCnts.uoQsCT}
                      </Typography>
                    </CardContent>
                  </CustomizedCard>
                </Grid>
                <Grid item xs={12} md={4} style={{ textDecoration: "none" }}>
                  <CustomizedCard variant="outlined">
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center", p: 2, py: 0 }}
                        component="h5"
                        variant="h5"
                      >
                        Unupdated Journeys
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", pt: 1 }}
                        variant="h5"
                        color="text.primary"
                      >
                        {allCnts.upQsCT}
                      </Typography>
                    </CardContent>
                  </CustomizedCard>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      }
    </DashboardLayout>
  );
}
